
















import { Component, Vue, Prop } from 'vue-property-decorator';
// eslint-disable-next-line
// @ts-ignore
import { Parallax } from '@/client/components/_theme';

@Component({
  components: {
    Parallax,
  },
})
export default class ParallaxHeader extends Vue {
  @Prop({ required: true })
  readonly image!: string;

  @Prop({ required: true })
  readonly textItems!: any;
}
