/*
  =====================================================
  Setting up this file is as straightforward as it gets
  The var names correlate with the page/Component, you
  just need to fill in the blanks here
  =====================================================
*/

export const loremBlockLarge = `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vehicula ut eros ac bibendum.
  Integer in orci at neque suscipit pellentesque tempor eu magna. Aenean eleifend erat et augue
  mollis, ac hendrerit eros porttitor. Vivamus sit amet est posuere, euismod nibh condimentum erat.
`;

export const loremBlockSmall = `
  Quisque at diam felis. Donec auctor eros arcu. Nunc et elit elit. Nullam eu ullamcorper mauris.
  Suspendisse egestas lacus quis volutpat cursus.
`;

export const loremSentence = 'Curabitur ligula leo, placerat at tincidunt eget, rhoncus vitae lacus.';

export const landingName = "Nice to meet you, I'm Elliott";

export const landingSubtitle = "Let's explore my passion for Development";

export const loremTitle = 'Lorem Ipsum';

export const aboutMeTitle = 'Something About Me';

export const aboutMeText = `Development has been the most satisfying field of work that I have explored.
My education started in Cybersecurity focusing mainly on router configuration, but after getting my degree the 
enthusiasm just wasn't what I hoped it would be. Exposure to development in my education encouraged 
me to seek more knowledge in my spare time, and from the enjoyment and sense of accomplishment that I received 
I knew that I wanted to develop! I've come a long way from my first custom Myspace page or "Hello World" app in Java,
but there is always more to learn and I can't wait to see what I'm capable of in the future.`;

export const contact = {
  title: loremTitle,
  subtitle: loremBlockSmall,
  form: {
    title: loremTitle,
    buttonText: loremTitle,
  },
};

export const landingContent = {
  header: {
    title: landingName,
    subtitle: landingSubtitle,
    button: loremTitle,
  },
  specialties: {
    title: aboutMeTitle,
    subtitle: aboutMeText,
    passionsSections: {
      title: 'Acquired Skills',
      subtitle: 'An ongoing list of some of the abilities I have learned over the years',
      passions: [
        {
          cardText: 'Back End',
          points: [
            {
              title: 'Logical Reasoning',
              text: 'Breaking down the task at hand into manageable pieces and verifying the functionality of each piece before moving onto the next.',
            },
            {
              title: 'Data Navigation',
              text: 'Ensuring that data is passed throughout the application as logically as possible, and is easily accessible whenever needed. ',
            },
            {
              title: 'Framework Utilization',
              text: 'Utilizing Web Frameworks to optimize web applications in a way that is organized, efficient, and legible.',
            },
          ],
        },
        {
          cardText: 'Front End',
          points: [
            {
              title: 'Node Structure',
              text: 'Implementing a proper hierarchy of nodes allowing the page to function properly, keeping styling in mind.',
            },
            {
              title: 'Stylization',
              text: 'Applying styling rules to the nodes of a page in order to to create a pleasant and easy to read user interface.',
            },
            {
              title: 'Framework Utilization',
              text: 'Making use of front end frameworks to create more robust and reactive styles of a page to ensure compatibility.',
            },
          ],
        },
        {
          cardText: 'Everything in Between',
          points: [
            {
              title: 'Web Server Implementation',
              text: 'Implementing a web server to host and display web applications keeping in mind security and availability.',
            },
            {
              title: 'Database Management',
              text: 'Creating a database and ensuring that the web application can create, read, update, and delete effectively.',
            },
            {
              title: 'Anything Else',
              text: 'Learning new solutions to problems are always enjoyable and rewarding. New skills are always welcome!',
            },
          ],
        },
      ],
    },
  },
};

export const aboutContent = {
  header: {
    title: loremTitle,
    subTitle: loremSentence,
    text: loremBlockLarge,
  },
  passionSections: [
    {
      title: loremTitle,
      passions: [
        {
          title: loremTitle,
          text: loremBlockLarge,
          cardText: loremTitle,
          buttonText: loremTitle,
          url: '/specialties/shrimp',
        },
        {
          title: loremTitle,
          text: loremBlockLarge,
          cardText: loremTitle,
          buttonText: loremTitle,
          url: '/specialties/aquascapes',
        },
        {
          title: loremTitle,
          text: loremBlockLarge,
          cardText: loremTitle,
          buttonText: loremTitle,
          url: '/specialties/discus',
        },
      ],
      fullImageBgSection: {
        title: loremSentence,
        text: loremBlockSmall,
      },
    },
  ],
} as const;

export const spec1Content = {
  header: {
    subTitle: 'Life Counter',
  },
  passionSections: [
    {
      title: 'TEST DESCRIPTION',
      text: 'PARAGRAPH',
      passions: [
        {
          title: 'loremTitle',
          text: loremBlockSmall,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'pay per lead',
          },
        },
        {
          title: loremTitle,
          text: loremBlockSmall,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'lead capture',
          },
        },
        {
          title: loremTitle,
          text: loremBlockSmall,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'portal',
          },
        },
      ],
      fullImageBgSection: {
        title: loremSentence,
        text: loremBlockSmall,
      },
    },
  ],
} as const;

export const spec2Content = {
  header: {
    subTitle: 'Todo Application',
  },
  passionSections: [
    {
      title: loremSentence,
      passions: [
        {
          title: loremTitle,
          text: loremBlockSmall,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'basic web',
          },
        },
        {
          title: loremTitle,
          text: loremBlockLarge,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'e commerce',
          },
        },
        {
          title: loremTitle,
          text: loremBlockSmall,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'commercial grade',
          },
        },
      ],
      fullImageBgSection: {
        title: loremSentence,
        text: loremBlockLarge,
      },
    },
  ],
} as const;

export const spec3Content = {
  header: {
    subTitle: 'Optosearch',
  },
  passionSections: [
    {
      title: loremSentence,
      passions: [
        {
          title: loremTitle,
          text: loremBlockLarge,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'app audit',
          },
        },
        {
          title: loremTitle,
          text: loremBlockLarge,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'business audit',
          },
        },
        {
          title: loremTitle,
          text: loremBlockLarge,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'comprehensive audit',
          },
        },
      ],
      fullImageBgSection: {
        title: loremSentence,
        text: loremBlockLarge,
      },
    },
  ],
} as const;

export const spec4Content = {
  header: {
    subTitle: 'Garage Repair Unlimited',
  },
  passionSections: [
    {
      title: loremTitle,
      passions: [
        {
          title: loremTitle,
          text: loremBlockSmall,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'basic maintenance',
          },
        },
        {
          title: loremTitle,
          text: loremBlockLarge,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'modernization',
          },
        },
        {
          title: loremTitle,
          text: loremBlockSmall,
          cardText: loremTitle,
          buttonText: loremTitle,
          routerConfig: {
            subtype: 'comprehensive',
          },
        },
      ],
      fullImageBgSection: {
        title: loremSentence,
        text: loremBlockSmall,
      },
    },
  ],
} as const;

export const allContent = {
  landing: {},
  about: aboutContent,
  spec1: spec1Content,
  spec2: spec2Content,
  spec3: spec3Content,
  spec4: spec4Content,
} as const;
