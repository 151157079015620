/*
  ===================================
  This controls the inner slug
  https:examplesite.com/innerSlug
  ===================================
*/

export const innerSlug = 'projects';

/*
  ==============================================
  This controls the outer slugs
  https:examplesite.com/innerSlug/outerSlug
  ==============================================
*/

export const outerSlugs = [
  'counter',
  'todo',
  'optosearch',
  'garage',
];

/*
  ==============================================
  Example setup for types
  These values correlate with any sub items
  In this case its specialties/type (innerSlug/outerSlug)
  ==============================================
*/

const type1Values = [
  'Sub Specialty 1',
  'Sub Specialty 2',
  'Sub Specialty 3',
];

const type1 = {
  key: 'type1',
  name: 'Game Life Counter',
  path: `/${innerSlug}/${outerSlugs[0]}`,
  values: type1Values,
  subtypes: {
    [type1Values[0]]: {
      key: type1Values[0],
      subtitle: 'Custom description for sub specialty 1',
      price: 'Starting at $30',
    },
    [type1Values[1]]: {
      key: type1Values[1],
      subtitle: 'Custom description for sub specialty 2',
      price: 'Starting at 500$',
    },
    [type1Values[2]]: {
      key: type1Values[2],
      subtitle: 'Custom description for sub specialty 3',
      price: 'Starting at 1k',
    },
  },
};

const type2Values = [
  'Sub Specialty 1',
  'Sub Specialty 2',
  'Sub Specialty 3',
];

const type2 = {
  key: 'type2',
  name: 'Todo App',
  path: `/${innerSlug}/${outerSlugs[1]}`,
  values: type2Values,
  subtypes: {
    [type1Values[0]]: {
      key: type1Values[0],
      subtitle: 'Custom description for sub specialty 1',
      price: 'Starting at $30',
    },
    [type1Values[1]]: {
      key: type1Values[1],
      subtitle: 'Custom description for sub specialty 2',
      price: 'Starting at 50$',
    },
    [type1Values[2]]: {
      key: type1Values[2],
      subtitle: 'Custom description for sub specialty 3',
      price: 'Starting at $200',
    },
  },
};

const type3Values = [
  'Sub Specialty 1',
  'Sub Specialty 2',
  'Sub Specialty 3',
];

const type3 = {
  key: 'type3',
  name: 'Optosearch',
  path: `/${innerSlug}/${outerSlugs[2]}`,
  values: type3Values,
  subtypes: {
    [type1Values[0]]: {
      key: type1Values[0],
      subtitle: 'Custom description for sub specialty 1',
      price: 'Starting at $300',
    },
    [type1Values[1]]: {
      key: type1Values[1],
      subtitle: 'Custom description for sub specialty 2',
      price: 'Starting at 500$',
    },
    [type1Values[2]]: {
      key: type1Values[2],
      subtitle: 'Custom description for sub specialty 3',
      price: 'Starting at 1k',
    },
  },
};

const type4Values = [
  'Sub Specialty 1',
  'Sub Specialty 2',
  'Sub Specialty 3',
];

const type4 = {
  key: 'type4',
  name: 'Garage Repair Unlimited',
  path: `/${innerSlug}/${outerSlugs[3]}`,
  values: type4Values,
  subtypes: {
    [type1Values[0]]: {
      key: type1Values[0],
      subtitle: 'Custom description for sub specialty 1',
      price: 'Starting at $500/month',
    },
    [type1Values[1]]: {
      key: type1Values[1],
      subtitle: 'Custom description for sub specialty 2',
      price: 'Starting at $1k/month',
    },
    [type1Values[2]]: {
      key: type1Values[2],
      subtitle: 'Custom description for sub specialty 3',
      price: 'Get in touch for pricing',
    },
  },
};

export const types: any = {
  type1,
  type2,
  type3,
  type4,
};

export const typeValues = [
  ...type1Values,
  ...type2Values,
  ...type3Values,
  ...type4Values,
];
