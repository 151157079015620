








































/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import RestStatus from '@/client/components/core/RestStatus.vue';
import Alert from '@/client/components/core/Alert.vue';
import {
  InfoArea,
  ServerResponse,
  GetStartReturn,
  PurchaseLead,
} from '@/server/types';
import * as Lookups from '@/client/components/lookups';
import api from '../util/api';
// @ts-ignore
import { InfoAreas, Tabs, Parallax } from './_theme';

@Component({
  components: {
    Alert,
    InfoAreas,
    Tabs,
    Parallax,
    RestStatus,
  },
})
export default class GetStarted extends Vue {
  Lookups = Lookups;

  boolean = false;
  name = '';
  email = '';
  message = '';
  location = '';
  alertMessage = '';
  responseType = '';
  showAlert = false;
  loading = false;
  error = false;
  specialty = '';
  type = '';
  headerImage = '';
  title = 'Contact me';
  subtitle = `Let me know you're intersted and I will get back to you as soon as possible`;
  imageLinks: any = {};

  contentLeft: Array<InfoArea> = [
    {
      colorIcon: 'success',
      icon: 'request_quote',
      title: 'Free Quote',
      description:
        'Get a no hassle quote today, we will find you the best prices for your area.',
    },
    {
      colorIcon: 'info',
      icon: 'speed',
      title: 'Responsive',
      description:
        "Don't like to wait? Neither do we. We'll get back to you within 24 hours.",
    },
  ];

  get isFormValidated() {
    return this.validateForm();
  }

  reset() {
    this.type = '';
    this.title = 'Get Started';
    this.subtitle = 'Let us know what you are looking for and we will help you get it!';
  }

  resetAlert() {
    this.alertMessage = '';
    this.responseType = '';
    this.showAlert = false;
  }

  buildHeaderImages() {
      Lookups.typeValues.forEach((value: any, index: number) => {
      // number of project-n images in src/client/assets/images directory
      const numImages = 5;
      const imageNumber = Math.floor(Math.random() * numImages) + 1;
      this.imageLinks[value] = require(`@/client/assets/images/office-${imageNumber}.jpg`);
    })
  }

  setHeaderImage(type: string) {
    this.headerImage = this.imageLinks[type];
    this.title =  Lookups.types[this.specialty].subtypes[type].key;
    this.subtitle =  Lookups.types[this.specialty].subtypes[type].subtitle;
  }

  popAlert(response: string, message: string) {
    this.responseType = response;
    this.alertMessage = message;
    this.showAlert = true;
    window.scrollTo(0, document.body.scrollHeight);
  }

  validateEmail(email: string) {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateForm(): boolean {
    if (this.name.length > 3) {
      if (this.validateEmail(this.email)) {
        return true;
      }
    }

    return false;
  }

  submitLead() {
    this.resetAlert();
    const lead: PurchaseLead = {
      name: this.name,
      email: this.email,
      message: this.message,
    };

    api('POST', 'https://rarequaticfinds.herokuapp.com/api/leads/main', lead)
      .then((response: ServerResponse) => {
        this.loading = false;
        this.popAlert('success', 'Message has been received, we will be in touch shortly.');
      })
      .catch((err) => {
        this.error = true;
        this.popAlert('danger', 'Unable to submit form, please try again later.');
        throw err;
      });
  }

  mounted() {
    this.buildHeaderImages();
  }
}
