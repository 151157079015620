


































/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import path from 'path';
import api from '../util/api';
// @ts-ignore
import { Parallax, InfoAreas, ProfileCard, FullBgCard } from '@/client/components/_theme';
import Contact from '@/client/components/Contact.vue';
import CitySection from '@/client/components/CitySection.vue';
import GetInTouch from  '@/client/components/GetInTouch.vue';
import PassionsSectionHome from '@/client/components/PassionsSectionV2.vue';
import { ImageStrings } from '@/server/types';
import * as Constants from '@/client/components/constants';

@Component({
  components: {
    Parallax,
    InfoAreas,
    ProfileCard,
    FullBgCard,
    Contact,
    CitySection,
    GetInTouch,
    PassionsSectionHome,
  },
})
export default class Landing extends Vue {
  Constants = Constants;
  headerImage: string | null = null;

  landingImage: Array<string | null> = [
    null,
    null,
    null,
    null,
  ];
}
