import Vue from 'vue';
import Router from 'vue-router';
import Landing from './client/components/Landing.vue';
import About from './client/components/About.vue';
import GetStarted from './client/components/GetStarted.vue';
import Terms from './client/components/Terms.vue';
import Specialty1 from './client/components/projects/Specialty1.vue';
import Specialty2 from './client/components/projects/Specialty2.vue';
import Specialty3 from './client/components/projects/Specialty3.vue';
import Specialty4 from './client/components/projects/Specialty4.vue';
import NotFound from './client/components/NotFound.vue';
import Specialties from './client/components/projects/Specialties.vue';
import * as Lookups from './client/components/lookups';

Vue.use(Router);

const routeArray = [
  {
    path: '/',
    name: 'welcome',
    component: Landing,
  },
  {
    path: '/about-us',
    name: 'about',
    component: About,
  },
  {
    path: `/${Lookups.innerSlug}`,
    name: Lookups.innerSlug,
    redirect: `/${Lookups.innerSlug}/${Lookups.outerSlugs[0]}`,
    component: Specialties,
    children: [
      {
        path: Lookups.outerSlugs[0],
        name: Lookups.outerSlugs[0],
        component: Specialty1,
      },
      {
        path: Lookups.outerSlugs[1],
        name: Lookups.outerSlugs[1],
        component: Specialty2,
      },
      {
        path: Lookups.outerSlugs[2],
        name: Lookups.outerSlugs[2],
        component: Specialty3,
      },
      {
        path: Lookups.outerSlugs[3],
        name: Lookups.outerSlugs[3],
        component: Specialty4,
      },
    ],
  },
  {
    path: '/start',
    name: 'start',
    component: GetStarted,
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: '*',
    redirect: {
      name: 'not-found',
    },
  },
];

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior(to: any, from: any) {
    return new Promise((resolve: Function) => {
      setTimeout(() => {
        if (to.name !== from.name) {
          resolve(window.scrollTo({ top: 0, behavior: 'smooth' }));
        } else {
          resolve();
        }
      }, 300);
    });
  },
  routes: routeArray,
});

export default router;
