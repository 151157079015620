import axios from 'axios';

export default async function api(method: any, endpoint: string, params?: any) {
  let config = {};

  if (params) {
    config = {
      method,
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
    };
  } else {
    config = {
      method,
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  return axios
    .request(config)
    .then((response) => response.data)
    .catch((err: any) => {
      console.log(err);
      throw err;
    });
}
