





import { Component, Vue } from 'vue-property-decorator';
import MainFooter from '@/client/components/layout/MainFooter.vue';

@Component({
  components: {
    MainFooter,
  },
})
export default class Specialties extends Vue {}
