































import { Component, Vue, Prop } from 'vue-property-decorator';
// eslint-disable-next-line
// @ts-ignore
import { FullBgCard, InfoAreas } from '@/client/components/_theme';
import { ImageStrings } from '@/server/types';
import * as Constants from '@/client/components/constants';

@Component({
  components: {
    FullBgCard,
    InfoAreas,
  },
})
export default class PassionsSectionHome extends Vue {
  /* eslint-disable */
  @Prop({ default: false })
  readonly noBg!: boolean;

  Constants = Constants;

  currentIndex = 0;
  passionImage: any = {
    image1: '/frontend.jpg',
    image2: '/between.jpg',
    image3: null,
    image4: null,
  };

  updateIndex = () => {
    const index = this.currentIndex;
    const newIndex = index + 1;

    this.currentIndex = newIndex;

    return newIndex;
  }
}
