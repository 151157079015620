









import { Component, Vue } from 'vue-property-decorator';
import MainNavbar from '@/client/components/layout/MainNavbar.vue';
import MainFooter from '@/client/components/layout/MainFooter.vue';

@Component({
  components: {
    MainNavbar,
    MainFooter,
  },
})
export default class App extends Vue {}
